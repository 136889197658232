<template>
    <div class="joinAgent">
        <div class="agent">
            <el-checkbox-group v-model="ruleForm.group_id" class="flex">
                <el-checkbox class="agent-item" v-for="(item,idx) in tagList" :key="idx" :label="item.id">{{item.name}}</el-checkbox>
            </el-checkbox-group>
        </div>
        <div class="flex flex-justify-around" style="padding:20px 0 10px;">
            <el-button @click="hide()" style="width: 80px;" size="small" type="primary" plain>取消</el-button>
            <el-button @click="submit()" style="width: 80px;" size="small" type="primary">确定</el-button>
        </div>

    </div>
</template>

<script>
    import { listAgencyGroup, addAgencyGroupProduct } from '@/service/white';
    export default {
        props: ['factory_product_no','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请选择经销商渠道分组', trigger: 'blur' }
                    ],
                },
                // 代理商分组
                tagList:[],
                ruleForm:{
                    group_id: [],
                    factory_product_no: [],
                }
            }
        },
        async created() {
            this.tagList = await listAgencyGroup({comp_id:this.$store.state.loginUser.comp.id});
            this.ruleForm.factory_product_no = [this.factory_product_no];
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                addAgencyGroupProduct(this.ruleForm).then(rst => {
                    this.hide();
                    this.$message.success("编辑成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped lang="less">
    .agent {
        width: 450px;
        margin: 30px auto 0;
        .agent-item {
            width: 200px;
            margin-bottom: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            &.el-checkbox:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
</style>